<template>
  <div>
    <h2>CONTACT INFO</h2>
    <div class="text-center">
      <v-btn
        text
        color="primary"
        href="tel:416-924-8741"
      >
        <v-icon small>mdi-phone</v-icon>
        <span class="pl-2">416-924-8741</span>
      </v-btn>
      <v-row>
        <v-col cols="12">
          <v-btn
            text
            color="primary"
            href="mailto:tcentralclerks@gmail.com"
          >
            <v-icon small>mdi-email</v-icon>
            <span class="pl-2">tcentralclerks@gmail.com</span>
          </v-btn>
        </v-col>
      </v-row>
      <hr class="mb-3 mt-3" />
      <v-btn
        x-large
        color="primary"
        href="/discipleshipCard"
      >
        <div class="m-10" style="color:white">DISCIPLESHIP CARD</div>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactDetails'
}
</script>
